"use client";
import { useMemo, useState } from "react";

import clsx from "clsx";
import Link from "next/link";

import { LinkButton, LinkButtonProps } from "@/components/buttons";
import { Column, Row } from "@/components/containers";
import { LoadingOverlay, Paper, TooltipIcon } from "@/components/displays";
import { MileIcon } from "@/components/icons";
import { useParsedGetMiles, useParsedGetMileSummary } from "@/queries";
import { numberFormat } from "@/utils";

import styles from "./MileSummary.module.scss";
import { MileHelpPopup } from "../MileHelpPopup/MileHelpPopup";

const LinkButtons: Record<
  MileSummaryProps["type"],
  { linkTo: string; linkText: string; linkColorClassName?: LinkButtonProps["color"] }
> = {
  mypage: {
    linkTo: "/mypage/mile/exchange",
    linkText: "ギフトに交換",
    linkColorClassName: "gray",
  },
  mile: {
    linkTo: "/mypage/mile/exchange",
    linkText: "ギフトに交換",
    linkColorClassName: "yellow",
  },
  mileExchange: {
    linkTo: "/mypage/mile",
    linkText: "マイル獲得・交換履歴",
    linkColorClassName: "gray",
  },
};

export interface MileSummaryProps {
  hideAnnotation?: boolean;
  hideTotalMiles?: boolean;
  hideMileLeverage?: boolean;
  hideTooltip?: boolean;
  type: "mypage" | "mile" | "mileExchange";
}

export function MileSummary({
  hideAnnotation,
  hideTotalMiles,
  hideTooltip,
  hideMileLeverage,
  type,
}: MileSummaryProps): React.ReactNode {
  const { data: mile } = useParsedGetMiles();
  const { data: mileSummary, isLoading: isGettingMileSummary } = useParsedGetMileSummary();
  const { availableMiles = 0, totalMiles = 0 } = mileSummary || {};

  const isMyPage = type === "mypage";
  const { linkTo, linkText, linkColorClassName } = LinkButtons[type];

  const [isMileHelpPopupOpen, setIsMileHelpPopupOpen] = useState(false);

  const earnMile = useMemo(() => (mile?.rankRate ? 100 * mile.rankRate : 100), [mile]);

  return (
    <LoadingOverlay isLoading={isGettingMileSummary}>
      <Paper
        padding={type === "mypage" ? "fixedSp" : "responsive"}
        className={styles.availableMileContainer}
      >
        {/* availableMilesLinkがある場合はデザインにより間隔が変わる */}
        <Column gap={isMyPage ? 20 : 10}>
          <Row align="center">
            <Row gap={10} flexFull>
              <MileIcon />
              <Row gap={5} flexFull>
                <p className="text__m text__bold">保有マイル</p>
                {!hideTooltip && (
                  <TooltipIcon
                    className={styles.tooltipIcon}
                    tooltipTitle="保有マイルとは？"
                    tooltipCloseButtonText="OK"
                    tooltips={
                      <p className="text__m">
                        BASE FOOD
                        継続コースでのご購入金額に応じて獲得したマイルです。貯まったマイルは、公式ノベルティや商品と交換することができます。
                      </p>
                    }
                    pcTooltips={`BASE FOOD 継続コースでのご購入金額に応じて獲得したマイルです。
                      貯まったマイルは、公式ノベルティや商品と交換することができます。`}
                  />
                )}
              </Row>
            </Row>
            {isMyPage ? (
              <Link href="/mypage/mile" className={styles.availableMileLink}>
                <Row gap={10} align="center">
                  {numberFormat(availableMiles)}
                  <i className="fas text__l fa-angle-right" />
                </Row>
              </Link>
            ) : (
              <div className={styles.availableMile}>{numberFormat(availableMiles)}</div>
            )}
          </Row>
          <Row reverse>
            <LinkButton href={linkTo} color={linkColorClassName} noMargin size="mini">
              {linkText}
            </LinkButton>
          </Row>
          {!hideAnnotation && availableMiles < totalMiles && (
            <Row className="text__s text__gray__dark">
              ※保有マイルには交換予定マイルを差し引いたマイル数が表示されています。
            </Row>
          )}
        </Column>
      </Paper>
      {!hideMileLeverage && (
        <Paper padding="responsive" className={styles.leverageContainer}>
          <Row align="center" justify="space-between">
            <Row align="center" gap={12}>
              <p className="text__m text__bold">現在のマイル倍率</p>
              <i
                className={clsx("fas", "fa-question-circle", "text__m", styles.tooltipIcon)}
                onClick={() => setIsMileHelpPopupOpen(true)}
              />
            </Row>
            <Row align="end">
              <span className={styles.leverage}>{mile.rankRate}</span>
              <span className={styles.leverageUnit}>倍</span>
            </Row>
          </Row>
          <p className="text__s text__gray__dark mg__top__s">
            継続コースでのお買い物100円（税込）につき、{earnMile}マイル貯まります。
          </p>
          <MileHelpPopup open={isMileHelpPopupOpen} onClose={() => setIsMileHelpPopupOpen(false)} />
        </Paper>
      )}
      {!hideTotalMiles && (
        <Paper padding="responsive">
          <Row gap={5} align="center">
            <p className="text__m text__bold">累計獲得マイル</p>
            <TooltipIcon
              className={styles.tooltipIcon}
              tooltipTitle="累計獲得マイルとは？"
              tooltipCloseButtonText="OK"
              tooltips={
                <p className="text__m mg__top__m">
                  BASE FOOD 継続コースでのご購入金額に応じて獲得したマイルの総数です。
                  <br />
                  累計獲得マイル数に応じてランクが上がり、ランクに応じた特典を受けることができます。
                  <br />
                  ランクの詳細は
                  <Link href="/mileprogram" className={styles.toMileProgramLink}>
                    こちら
                  </Link>
                  をご覧ください。
                </p>
              }
              pcTooltips={
                <>
                  BASE FOOD 継続コースでのご購入金額に応じて獲得したマイルの総数です。
                  <br />
                  累計獲得マイル数に応じてランクが上がり、ランクに応じた特典を受けることができます。
                  <br />
                  ランクの詳細は
                  <Link href="/mileprogram" className={styles.toMileProgramLink}>
                    こちら
                  </Link>
                  をご覧ください。
                </>
              }
            />
            <div className={styles.totalMile}>{numberFormat(totalMiles)}</div>
          </Row>
        </Paper>
      )}
    </LoadingOverlay>
  );
}
