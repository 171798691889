import React from "react";

import { Column, Row } from "@/components/containers";
import { Modal } from "@/components/feedbacks";
import { Button } from "@/components/inputs";
import { objectValues } from "@/utils/object";

import { rankLeverages } from "./consts";
import styles from "./MileHelpPopup.module.scss";

export interface MileHelpPopupProps {
  open: boolean;
  onClose: () => void;
}

export function MileHelpPopup({ open, onClose }: MileHelpPopupProps) {
  return (
    <Modal
      displayCloseButton={false}
      open={open}
      backdropClose
      closeModal={() => {}}
      className={styles.root}
      header={
        <Row gap={10}>
          <h3 className={styles.title}>マイル倍率とは？</h3>
        </Row>
      }
      footer={
        <Row gap={10}>
          <Button variants="gray" onClick={onClose}>
            OK
          </Button>
        </Row>
      }
    >
      <Column className={styles.body}>
        <p className={styles.description}>
          継続コースでのお買い物100円（税込）ごとに獲得できるマイル数の倍率です。ランクによってマイル倍率が異なります。
        </p>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead className={styles.tableHeader}>
              <tr>
                <th className={styles.tableHeaderCell}>ランク</th>
                <th className={styles.tableHeaderCell}>マイル倍率</th>
              </tr>
            </thead>
            <tbody>
              {objectValues(rankLeverages).map((leverage) => (
                <tr key={leverage.title} className={styles.tableBodyRow}>
                  <td className={styles.tableBodyCell}>
                    <Row align="center" gap={10} className={styles.rankTitleContainer}>
                      <img src={leverage.rankImage} alt={leverage.title} width={32} height={32} />
                      <p className={styles.leverageTitle}>{leverage.title}</p>
                    </Row>
                  </td>
                  <td className={styles.tableBodyCell}>
                    <p>
                      <span className={styles.leverageRate}>{leverage.rate}</span>倍
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Column>
    </Modal>
  );
}
