export const rankLeverages = {
  Economy: {
    title: "エコノミー\nクラス",
    rate: 1,
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_economy.svg",
  },
  Business: {
    title: "ビジネス\nクラス",
    rate: 1.5,
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_business.svg",
  },
  First: {
    title: "ファースト\nクラス",
    rate: 2,
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_first.svg",
  },
  Vip: {
    title: "VIPクラス",
    rate: 3,
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_vip.svg",
  },
} as const;
