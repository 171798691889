"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";
import Image from "next/image";
import { FadeLoader } from "react-spinners";

import { Row } from "@/components/containers";
import { XIcon } from "@/components/icons";
import { withCsr } from "@/utils";
import { useOnce } from "@/utils/hooks";

import { getShareInfo } from "./helpers";
import styles from "./SnsShareModal.module.scss";
import { MyPageModal } from "../../../MyPageModal";

export interface SnsShareModalProps {
  open: boolean;
  onClose: () => void;
}

export const SnsShareModal = withCsr(function SnsShareModal({
  open,
  onClose,
}: SnsShareModalProps): React.ReactNode {
  const [shareInfo, setShareInfo] = useState<{ link: string; image: string } | null>(null);

  useOnce(async () => {
    // モーダルを開いた時に、1度だけ画像を取得する
    const res = await getShareInfo();
    setShareInfo(res);
  }, open);

  const handleShareToTwitter = useCallback(() => {
    // hashtagsを使うより、テキストに含める方が改行をコントロールできるため、テキストに含める
    if (!shareInfo) return;
    const shareText = [
      "ベースフードで美味しい健康習慣！",
      "#ベースブレッド #栄養ダッシュボード #PR",
    ].join("\n");
    const params = new URLSearchParams({ text: shareText, url: shareInfo!.link }).toString();
    window.open(`https://x.com/share?${params}`, "_blank")?.focus();
    onClose();
  }, [onClose, shareInfo]);

  return (
    <MyPageModal
      open={open}
      closeModal={onClose}
      footer={
        <button type="button" className="btn inline gray square text__m" onClick={onClose}>
          キャンセル
        </button>
      }
    >
      <p className="text__l text__bold text__center pd__top__m">
        ベースフードライフを
        <br />
        シェアしよう！
      </p>

      <Row align="center" justify="center">
        {shareInfo ? (
          <Image
            className="share__image mg__top__m"
            src={shareInfo.image}
            alt="ベースフードでおいしい健康習慣"
            width={300}
            height={160}
          />
        ) : (
          <FadeLoader loading={true} />
        )}
      </Row>
      <p className="text__s text__gray__dark mg__top__s">
        ※最新の情報が未反映の場合は、翌日以降に再度お試しください。
      </p>
      <div className="text__center mg__top__m flex justify-center">
        <div className="text__center text__bold text__s text__m__pc">
          <a
            href="#"
            className={clsx("flex align-center", styles.twitterContainer)}
            onClick={handleShareToTwitter}
          >
            <XIcon height="2rem" />
            <span className={clsx("text__m text__bold", styles.borderBottom)}>Xでシェア</span>
          </a>
        </div>
      </div>
    </MyPageModal>
  );
});
