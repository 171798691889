import clsx from "clsx";

import { ResponsiveGrid, ResponsiveGridProps } from "@/components";

import { ImageLink, ImageLinkItem } from "./ImageLinkItem";
import styles from "./ImageLinkList.module.scss";

interface ImageLinkListProps {
  cols: number; // 何個ずつ並べるか
  flow: ResponsiveGridProps<"ul">["flow"];
  images: ImageLink[];
}

export function ImageLinkList({ images, cols, flow }: ImageLinkListProps): React.ReactNode {
  return (
    <ResponsiveGrid cols={cols} flow={flow} gap={10} className={clsx(styles.root)} as="ul">
      {images.map((image, index) => (
        <ImageLinkItem key={index} {...image} />
      ))}
    </ResponsiveGrid>
  );
}
