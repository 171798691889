"use client";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { CircularProgressBar, Paper } from "@/components/displays";
import { RANK_MAP } from "@/components/template/mypage/settings";
import { useParsedGetPointMile } from "@/queries";
import rankStyles from "@/style/rankColor.module.scss";

import styles from "./MileRankInfo.module.scss";

export interface MileRankInfoProps {
  rankCircleBgColor?: string;
  bgTransparent?: boolean;
}

export function MileRankInfo({
  rankCircleBgColor,
  bgTransparent,
}: MileRankInfoProps): React.ReactNode {
  const { data: miles } = useParsedGetPointMile();
  const rankName = miles.rankName;
  const mile = miles.totalMile;
  const nextRankUpMile = miles.nextRankUpMile;
  const nextRankUpRate = miles.nextRankUpRate;
  const bgStyle = bgTransparent ? { background: "transparent" } : {};

  return (
    <Paper padding="fixed20" style={bgStyle}>
      <Column gap={20} align="center">
        <h1 className="text__l text__bold">現在のランク</h1>
        <div className={styles.rankDetailsContainer}>
          <dl className={clsx(styles.mileUserDefinition)}>
            <dt>
              <img
                src={RANK_MAP[rankName].rankImage}
                width="80"
                height="80"
                className={styles.rankImg}
                alt={RANK_MAP[rankName].rankNameJp}
              />
            </dt>
            <dd className={clsx(styles.mileUserDescription, "text__left")}>
              <p className={clsx("text__l", "text__bold", styles[rankName])}>
                {RANK_MAP[rankName].rankNameJp}
              </p>
              <p className="text__s">
                累計獲得マイル数
                <span className={clsx("text__m", "text__bold", styles.mileAmount)}>
                  {mile.toLocaleString()}
                </span>
              </p>
            </dd>
          </dl>
          {rankName !== "Vip" && (
            <>
              <div className={styles.dashedLine} />
              <dl className={clsx(styles.mileUserDefinition)}>
                <dt>
                  <CircularProgressBar
                    progress={nextRankUpRate}
                    bgColor={rankCircleBgColor}
                    barClassName={rankStyles[`${rankName}ProgressBar`]}
                  />
                </dt>
                <dd className={clsx(styles.mileUserDescription, "text__left")}>
                  <p className="text__m mg__bottom__xs">次のランクまであと</p>
                  <p className="text__m">
                    <span className="text__xl text__bold">{nextRankUpMile.toLocaleString()}</span>
                    マイル
                  </p>
                </dd>
              </dl>
            </>
          )}
        </div>
      </Column>
    </Paper>
  );
}
