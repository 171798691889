"use client";
import { useMemo } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { MileIcon } from "@/components/icons";
import { MILE_RANK_PARAMETER } from "@/configs/system";
import { useParsedGetMiles } from "@/queries";
import { numberFormat } from "@/utils";

import styles from "./ExpectedMileSection.module.scss";

export const rankTitles = [
  { name: "Economy", title: "エコノミークラス" },
  { name: "Business", title: "ビジネスクラス" },
  { name: "First", title: "ファーストクラス" },
  { name: "Vip", title: "VIPクラス" },
] as const;

interface ExpectedMileSectionProps {
  totalPrice: number;
  totalGetMile: number;
}
export function ExpectedMileSection({ totalGetMile, totalPrice }: ExpectedMileSectionProps) {
  const { data: mileData } = useParsedGetMiles();

  const message = useMemo(() => {
    if (totalPrice === 0) return "";
    if (!mileData || mileData.rankName === "Vip") return "";
    const currentRankIndex = rankTitles.findIndex((rank) => rank.name === mileData.rankName);
    const nextRank = rankTitles[currentRankIndex + 1];
    const remainingPrice = mileData.nextRankUpYen - totalPrice;
    if (remainingPrice >= MILE_RANK_PARAMETER.rankUpPromotionPriceDiff) return "";

    if (remainingPrice <= 0) {
      return `今回の購入で${nextRank.title}にランクアップ！次回からのマイル倍率が${mileData.nextRankRate}倍に！`;
    }
    return `あと¥${numberFormat(remainingPrice)}の購入で${nextRank.title}にランクアップ！マイル倍率が${mileData.nextRankRate}倍に！`;
  }, [mileData, totalPrice]);

  if (totalGetMile === 0) return null;

  return (
    <Column className={styles.root} gap={12}>
      <Row align="center" justify="space-between">
        <Row gap={8}>
          <MileIcon />
          <p>獲得予定マイル</p>
        </Row>
        <Row>
          <p className={clsx("text__xl", "text__bold")}>{numberFormat(totalGetMile)}</p>
        </Row>
      </Row>
      <Row justify="end">
        <p className={clsx("text__s")}>現在のマイル倍率：{mileData.rankRate}倍</p>
      </Row>
      {message && <p>{message}</p>}
    </Column>
  );
}
