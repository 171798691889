import { getInviteCode, getMyPageSummaryImageName } from "@/generated/axios-functions/payseAPI";

export const getAssetImageFullUrl = (imagePath: string) => {
  const env = process.env.NEXT_PUBLIC_APP_ENV;
  switch (env) {
    case "development":
    case "local":
    case "production":
      return `https://asset.basefood.co.jp/share/${imagePath}`;
    default:
      return `https://asset-${env}.dev.basefood.co.jp/share/${imagePath}`;
  }
};

const analyticsParams = {
  utm_medium: "official-site",
  utm_source: "mypage",
  utm_campaign: "invite-friends_mypage_dashboard",
  argument: "WTgLzY2D",
  dmai: "a63fc85c1ed6e6",
};

export const getShareInfo = async () => {
  const [{ code }, imageRes] = await Promise.all([getInviteCode(), getMyPageSummaryImageName()]);
  if (code && imageRes) {
    const ogp = imageRes.replace(".png", "");
    const origin = window.location.origin;
    const queryParams = new URLSearchParams({
      ogp,
      code,
      ...analyticsParams,
    });
    return {
      link: `${origin}/lp/invite?${queryParams.toString()}`,
      image: getAssetImageFullUrl(imageRes),
    };
  } else {
    throw new Error("Failed to fetch invite code");
  }
};
