import { subscriptionProductAnnotation } from "@/configs/products";
import { productNameSkuMap } from "@/configs/skuDB";
import { CartCalcApiResponse } from "@/generated/open-api/schemas";
import {
  breadCategoryProductNames,
  cookieCategoryProductNames,
  deliPastaCategoryProductNames,
  pancakeCategoryProductNames,
  pastaCategoryProductNames,
  sauceCategoryProductNames,
  yakisobaCategoryProductNames,
} from "@/models/product/consts";
import { ProductModel } from "@/models/product/type";
import { SnakeToCamelCaseNested } from "@/utils";

import {
  newProductNames,
  limitedProductNames,
  subscriptionProductCautions,
  subscriptionProductDescriptions,
} from "./consts";

export const orderDeliveryStatusColors = {
  None: "yellow",
  PartlyShipped: "green",
  Shipped: "green",
  Cancel: "red",
} as const;

export type Category = "bread" | "cookie" | "pasta" | "pancake" | "deliPasta";

export const getProductCategory = <T extends { name: string }>(product: T): Category => {
  if ((breadCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
    return "bread";
  } else if ((cookieCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
    return "cookie";
  } else if (
    (pastaCategoryProductNames as ReadonlyArray<string>).includes(product.name) ||
    (yakisobaCategoryProductNames as ReadonlyArray<string>).includes(product.name) ||
    (sauceCategoryProductNames as ReadonlyArray<string>).includes(product.name)
  ) {
    // 焼きそば、ソースセットはパスタに分類される
    return "pasta";
  } else if ((pancakeCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
    return "pancake";
  } else if ((deliPastaCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
    // これは冷凍のやつ
    return "deliPasta";
  }
  throw new Error(`Invalid product name: ${product.name}`);
};

export const groupProductsByCategory = <T extends { name: string }>(products: T[]) => {
  return products.reduce(
    (acc, product) => {
      const category = getProductCategory(product);
      acc[category].push(product);
      return acc;
    },
    {
      bread: [] as T[],
      cookie: [] as T[],
      pasta: [] as T[],
      pancake: [] as T[],
      deliPasta: [] as T[],
    }
  );
};

interface ConvertSubscriptionProductParams {
  product: ProductModel;
  defaultProduct?: ProductModel;
  brandNewDiscounts: SnakeToCamelCaseNested<
    Required<CartCalcApiResponse>["cart"]
  >["productBrandNewDiscounts"];
}

export function convertSubscriptionProduct({
  product,
  defaultProduct,
  brandNewDiscounts,
}: ConvertSubscriptionProductParams) {
  return {
    ...product,
    isNew: newProductNames.includes(product.name),
    isLimited: limitedProductNames.includes(product.name),
    description:
      subscriptionProductDescriptions.find((description) => description.sku === product.sku)
        ?.description ?? "",
    brandNewDiscount:
      brandNewDiscounts?.find(
        (productBrandNewDiscount) => productBrandNewDiscount.variantId === product.variantId
      ) ?? null,
    // ソースセットのタイトルは非表示
    showTitle: !(product.sku === productNameSkuMap.sauce_set),
    cautions:
      subscriptionProductCautions.find((caution) => caution.sku === product.sku)?.cautions ?? [],
    annotation:
      subscriptionProductAnnotation.find((annotation) => annotation.sku === product.sku)
        ?.annotation ?? [],
    isChanged: (defaultProduct?.quantity ?? 0) !== product.quantity,
  };
}

export type SubscriptionProduct = ReturnType<typeof convertSubscriptionProduct>;
