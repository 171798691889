"use client";
import Link from "next/link";

import { Image } from "@/components";
import { withCsr } from "@/utils";

import bnrMilkSp from "$/images/banner/bnr_milk_sp.jpg";

import styles from "./Announcements.module.scss";
import { useImageLinks } from "./helpers";
import { ImageLinkList } from "../ImageLinkList";

export const Announcements = withCsr(function Announcements(): React.ReactNode {
  const { imageLinks } = useImageLinks();

  return (
    <div>
      <ImageLinkList images={imageLinks} cols={2} flow={{ sp: "column", pc: "row" }} />

      <div className="pd__bottom__s">
        <Link href="/products/basebread/milk" className={styles.link}>
          <Image
            src={bnrMilkSp}
            alt="BASE BREAD ミルク 新発売"
            containerClassName={styles.imageContainer}
            className={styles.image}
          />
        </Link>
      </div>
    </div>
  );
});
